<template>
    <b-modal v-model="isVisible" size="lg" scrollable hide-footer hide-header>
        <div class="modal__content">
            <div class="modal__header">
                <p class="modal__title">Filters</p>
                <img src="@/assets/images/icons/close.png" alt="Close" @click="closeModal">
            </div>
            
            <slot></slot>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            isVisible: false,
        }
    },
    methods: {
        showModal() {
            this.isVisible = true;
        },
        closeModal() {
            this.isVisible = false;
        }
    }
}
</script>

<style scoped>
.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;

    img {
        width: 13px;
        height: 13px;
        cursor: pointer;
    }

    & .modal__title {
        padding: 0;
    }
}
</style>